$loss: #146dc8;
$gain: #e01515;
$link: #006ccc;
$green: #2EB975;
$grey: #eeeeee;
$light-grey: #d7d7d7;
$link-visited: #006ccc;
$small-font: 12px;
$medium-font: 14px;
$normal-font: 16px;
$title-font: 20px;
$smallphone-max-breakpoint: 320px;
$phone-max-breakpoint: 350px;
$smallphone-landscape-max-breakpoint: 600px;
$portrailt-tablet-max: 812px;
$landscape-tablet-min: 813px;
$landscape-tablet-max: 1200px;
$desktop-min: 1201px;

.ag-center-cols-clipper {
  min-height: 84px !important;
}

.ag-header-cell-label {
  color:#616161;
  opacity: 0.6;
}

.no-display {
  display: none;
}

a {
  color: $link;
  text-decoration: none;

  &:active,
  &:hover,
  &:visited {
    color: $link;
    text-decoration: none;
  }
}

ul {
  padding: 0;
  list-style: none;
}

.grey {
  color: silver;
}

.drag-handle {
  box-sizing: border-box;
  cursor: ns-resize;
  display: inline-block;
  height: 48px;
  margin: 0;
  padding: 12px;
  opacity: 0.5;
  width: 48px;
}

.add-button {
  opacity: 0.25;
}

td span {
  text-transform: none !important;
  padding-left: 0;
}

/* Status message displayed in place of no content or an empty list */

.custom-ag-grid {
    position: relative;
}

.custom-ag-grid .ag-theme-balham .ag-row-selected {
  background-color: #fff9e5;
}

.ag-cell {
  cursor: pointer;
}

.ag-theme-balham {
  .flash-blue {
    --ag-value-change-value-highlight-background-color: #0ff;
  }
  .flash-yellow {
    --ag-value-change-value-highlight-background-color: #ff0;
  }
}

/*
 * Reset z-index for AG Grid popup edit
 * to be able to explicitly set Symbol Search
 * z-index and render it on top of Ticker component
 */
.ag-popup .ag-popup-editor {
  z-index: inherit;
}


.trade-bar.quote-name {
  margin-right: 9px;
  margin-left: 4px;
  font-size: 19.6px;
  vertical-align: 1px;
}

.trade-bar.quote-last.value {
  color: #222;
  display: inline-block;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 0.25rem;
  margin-right: 9px;
  text-align: left;
}

.quote-net-change.value {
  line-height: 1.6rem;
  text-align: left;

  > .number {
    font-size: 0.9em;

    &.negative {
      color: $loss;
    }
  }
}

.fadeIn {
  opacity: 1;
  animation-name: fade-in-opacity;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(.25,1.14,.59,.96);
  animation-duration: 1.4s;
  animation-fill-mode: backwards;
}

@keyframes fade-in-opacity {
  0% { opacity: 0.4 }
  100% { opacity: 1 }
}

.trade-bar.quote-net-change.value {
  line-height: 1.6rem;
  text-align: left;

  > .number {
    font-size: 0.9em;
    color: $gain;

    &.negative {
      color: $loss;
    }
  }
}

.no-content {
  color: #999;
  display: block;
  font-size: 1.3em;
  padding: 1.5em 0.5em;
  text-align: center;
}

.smaller-text-label {
  font-size: 1em !important;
}

.page-title-bar {
  background: #fafafa;
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.2);
  clear: both;
  height: 48px;
  line-height: 48px;
  position: relative;
  transition: box-shadow 100ms linear;

  &.fixed {
    /*box-shadow: 0 4px 4px 0 rgba(0,0,0,0.2);*/
    left: 0;
    position: fixed;
    right: 0;
    top: 46px;

    /* height of the fixed Monex navigation bar */
    z-index: 1030;
  }
}

.page-title-bar-empty {
  width: 100%;
  height: 28px;
}

/* When accessing the site through Monex Vision, the topmost Monex navigation
   * bar is not displayed. This causes the page title bar to be fixed to the
   * wrong spot, with a 46-pixel gap above it. Fortunately we can detect this
   * case by the presence of the hybrid_monex_co_jp class on the body element.
   */

body.hybrid_monex_co_jp .page-title-bar.fixed {
  top: 0;
}

/* By using offsetting positive padding and negative margin, we gain the
   * ability to use the Element.scrollIntoView() function to align the top edge
   * of our content with the bottom of the fixed header bar, which is 46px tall.
   */

#root {
  margin-top: -46px;
  padding-top: 46px;
  z-index: 0;

  /* ensures Monex nav elements are above our content */
}

/* When no header bar is present, we remove the padding and margin above. */

body.hybrid_monex_co_jp #root {
  margin-top: 0;
  padding-top: 0;
}

.page-title-bar > {
  .left-button,
  .right-button {
    height: 48px;
    position: absolute;
    top: 0;
  }

  .left-button {
    left: 0;
  }

  .right-button {
    right: 0;
  }
}

.icon-soft {
  color: #BBBBBB;
}

.headline {
  background: #ffffff;
  border-bottom: 1px solid #e2e2e2;
  border-radius: 2px;
  padding-left: 15px;
  padding-right: 15px;
  overflow: hidden;
  position: relative;
  padding-top: 15px;
  padding-bottom: 10px;
  -webkit-transition: max-height 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: max-height 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  max-height: calc(4rem + 15px);
}

.article > .header,
.headline > .header {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 90%;
  text-size-adjust: 90%;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.9em;
  line-height: 1.2rem;
  margin-left: -8px;
  padding: 4px 4px 4px 12px;
}

.article > .header {
  background: #f2f2f2;

  > .provider {
    float: right;
  }
}

.headline > .header > .provider {
  float: right;
  padding-top: 5px;
  font-size: $small-font;
}

.article > {
  .article-headline {
    margin-bottom: 10px;
    font-size: larger;
    font-weight: bolder;
  }

  .article-body {
    margin-right: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.headline > a > {
  .title {
    /* margin-right: 29px; */
    float: left;
    overflow: hidden;
    font-size: 15px;
    text-overflow: ellipsis;
    max-height: 45px;
  }

  .icon {
    width: 48px;
    float: right;
    margin-left: -48px;
  }
}

.article {
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px;
  margin: 4px 4px;
  overflow: hidden;
  padding-left: 8px;
  position: relative;

  > {
    .subtitle {
      overflow: auto;
      margin-bottom: 3px;
    }

    .headline {
      overflow: auto;
      max-height: 500px;
    }

    .subtitle > .timestamp {
      float: left;
    }
  }
}

.news-secondary > .timestamp {
  float: left;
}

.article > .subtitle > .provider,
.news-secondary > .provider {
  float: right;
}

.article > .article-body {
  margin: 0px;
  padding: 0px;
  margin-left: -8px;

  > .inner-body {
    position: relative;
    background: #fafafa;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #f2f2f2;
    border-top: 0px;
    padding: 5px;

    &.nowrap {
      position: relative;
      background: #fafafa;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border: 1px solid #f8f8f8;
      padding: 5px;
      padding-right: 50px;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      border-top: 0px;
      white-space: nowrap;
      width: 96%;
    }
  }
}

.is-loading > .loading-message {
  padding-top: 10px;
  text-align: center;
  width: 100%;
  color: silver;
}

.expand-button {
  align-content: center;
  margin: auto;
  width: 100%;
  text-align: center;

  &.margin-top {
    margin-top: 10px;
  }

  > button {
    background-color: #f8f8f8 !important;
    border-top-color: #f8f8f8 !important;
  }

  .more-link {
    color: #0033cc;
    margin-top: 16px;
    margin-bottom: 20px;

    &::after {
      width: 11px;
      height: 11px;
      color: $light-grey;
      opacity: 1;
      content: ' \25BE';
    }
  }
}

.news-list-style {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: -1em;
  margin-right: 1em;
  width: inherit;
}

.symbol-headlines {
  margin-bottom: 5px;
  min-height: 300px;
}

.page-title-bar {
  > .title {
    bottom: 0;
    font-size: 1.2em;
    left: 8px;
    overflow: hidden;
    position: absolute;
    right: 8px;
    text-overflow: ellipsis;
    top: 0;
    white-space: nowrap;
  }

  &.has-left-button > .title {
    left: 48px;
  }

  &.has-right-button > .title {
    right: 48px;
  }
}

/* The page-title-bar-fixed class is applied to the element that contains the
   * page-title-bar element when the latter element is moved to a fixed state.
   * Since this takes it out of the document flow, content inside the parent
   * element will jump upwards by an amount equal to the height of the
   * page-title-bar element. This class is applied so that padding can be added
   * to the top of the element to compensate. */

.page-title-bar-fixed {
  padding-top: 48px;
}

.material-icons {
  &.edit::before {
    content: '\e3c9';
  }

  &.clear::before {
    content: '\e14c';
  }
}

.search-box {
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 4px;
  padding-bottom: 3px;
  height: 40px;
  position: relative;
  background: #fff;
  font-size: $normal-font;

  > .search-icon {
    position: absolute;
    right: 1em;
    top: 0.5em;
    width: 1em;
    opacity: 0.3;
  }
}

.trade-status {
  color: #fe0000;
  font-weight: bold;
  float: right;
  margin-right: 5px;
}

/* An element with the quote-flag class is used to indicate some kind of status
   * for a price or other value. Examples of such statuses include a new 52-week
   * high or an unusual ask or bid quote status. Quote flags can be tagged with
   * the high or low classes; high flags go with the high price and with bid
   * quotes, while low flags go with the low price and with ask quotes. The
   * content of the element with the quote-flag class is typically a single
   * character that conveys the meaning of the flag. */

.quote-flag {
  font-weight: bold;
  padding: 0 0.4em;

  &.high {
    color: $gain;
  }

  &.low {
    color: $loss;
  }
}

.cashSellButton,
.cashBuyButton {
  color: white;
}

.marginBuyButton,
.marginSellButton {
  color: red;
  background: blue;
}

.globalAlertBar {
  display: block;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  color: #333;
  background: #f5f5f5;
  font-size: 0.9em;
  top: 0px;
  min-height: 48px;
  width: 100%;

  &.expanded {
    max-height: unset;
  }

  &.alert {
    border-left: 4px solid $gain;
  }

  &.info {
    border-left: 4px solid #DBAB00;
  }
}

.globalAlertIcon {
  float: left;
  width: 0px;
  height: 0px;
  padding: 24px;

  &::before {
    display: inline-block;
    position: absolute;
    top: 16px;
    left: 15px;
  }

  &.alert{
    &::before {
      content: url(../icons/ico-warning_01.png);
    }
  }

  &.info{
    &::before {
      content: url(../icons/ic_info_next.png);
    }
  }
}

.globalAlertMessage {
  float: left;
  width: calc(100% - 96px);
  overflow: hidden;
  padding-top: 13px;
  padding-bottom: 13px;

  &.expanded {
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
  }
}

.globalAlertDismiss {
  float: right;
}

.quote-cards {
  font-size: $medium-font;
  list-style: none;
  margin-top: 0px;

  * {
    box-sizing: border-box;

    &::before,
    &::after {
      box-sizing: border-box;
    }
  }
}

.quote-card {
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px;

  /*box-shadow: 0 2px 2px -2px rgba(0,0,0,0.2);*/
  cursor: pointer;
  margin: 4px 4px;
  overflow: hidden;
  padding-left: 8px;
  position: relative;
  transition: max-height 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);

  /* easeOutQuad */
  max-height: calc(4.4rem + 16px);
  min-width: 312px;

  &::before {
    background: #eee linear-gradient(0deg, #ddd, #eee);
    bottom: 0;
    border-radius: 2px;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    width: 4px;
  }

  &.gain::before {
    background: #ffcccc linear-gradient(0deg, #ffa6a6, #ffcccc);
  }

  &.loss::before {
    background: #cce1ff linear-gradient(0deg, #a6cbff, #cce1ff);
  }

  &::after {
    clear: both;
    content: '';
    display: table;
    height: 8px;
  }

  &:hover {
    background: #f8f8f8;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }

  &.position {
    max-height: calc(4.7rem + 35px);
  }
}

.quote-card-header {
  text-size-adjust: 100%;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background: #f2f2f2;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.9em;
  line-height: 1.2rem;
  margin-left: -8px;

  /* pull the left boundary to the left side of the quote card */
  padding: 4px 4px 4px 12px;

  /* 8px extra left padding to compensate for negative margin */

  .symbol-name {
    color: #000;
    font-weight: bold;
  }

  .fade-out {
    background-image: linear-gradient(90deg,#000000 80%,rgba(0,0,0,0));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .symbol-code {
    margin-left: 0.5em;
  }

  .symbol-exchange {
    margin-left: 0.2em;
  }

  .quote-time {
    float: right;
  }

  .quote-right {
    float: right;
  }

  .quote-cash-margin-header-left {
    width: 38%;
    display: inline-block;
  }

  .quote-cash-margin-header-center {
    width: 32%;
    display: inline-block;
    font-size: 11px;
    text-align: end;
  }

  .quote-cash-margin-header-right {
    display: inline-block;
    font-size: 11px;
    float: right;
  }
}

.quote-card-column-group {
  display: inline-block;
  float: left;
  padding: 4px;

  &::after {
    clear: both;
    content: '';
    display: table;
  }

  &.historical {
    &.margin {
      margin-bottom: 20px;
    }
  }
}

.quote-card-column-group .margin-description {
  height: 1rem;

  .open-date {
    font-size: 12px;
  }
}

.margin-type {
  .values {
    .flag {
      font-size: 12px;
      background-color: #9E9E9E;
      color: white;
      padding: 3px;
    }

    .long,
    .short {
      font-size: 12px;
      color: white;
      padding: 3px;
    }

    .long {
      background-color: #d84e4e;
    }

    .short {
      background-color: #3d9ad3;
    }
  }
}

.quote-card-column {
  display: inline-block;
  float: left;
  height: 3.2rem;
  line-height: 1.6rem;
  width: 25%;

  .symbol-name {
    color: #000;
    font-weight: bold;
  }

  .symbol-code {
    margin-left: 0.5em;
  }

  .symbol-exchange {
    margin-left: 0.2em;
  }

  .symbol-name,
  .symbol-code,
  .symbol-exchange
  {
    font-size: 11px;
  }

  &.position {
    height: 4.2rem;
  }

  .quote-time-left {
    display: block;
  }

  .quote-time-value {
    font-size: 11px;
  }
}

.margin-type.value,
.open-date.value,
.number {
  font-family: 'Roboto Condensed', sans-serif;
}

.quote-card-column.price {
  width: 40%;

  > {
    .quote-last.value {
      color: #222;
      display: inline-block;
      font-size: 1.4em;
      line-height: 1.4rem;
      margin-bottom: 0.25rem;
      text-align: left;
    }

    .quote-net-change.value {
      line-height: 1.6rem;
      text-align: left;

      > .number {
        font-size: 0.9em;

        &.negative {
          color: $loss;
        }
      }
    }
  }
}

.number.negative.color {
  color: $loss;
}

.quote-card-column.price > .quote-net-change.value > .number.positive,
.number.positive.color {
  color: $gain;
}

.quote-card-column.price-shares {
  width: 30%;
}

.quote-card-column.pnl {
  width: 30%;

  > {
    .pnl-net-pct.value,
    .pnl-net-yen.value {
      line-height: 1.6rem;
      text-align: right;

      > .number {
        font-size: 0.9em;

        &.negative {
          color: $loss;
        }
      }
    }
  }
}

.quote-card-column.pnl > {
  .pnl-net-yen.value,
  .pnl-net-pct.value > .number.positive,
    .number.positive.color {
      color: $gain;
    }
}

.quote-card-column {
  &.ask-bid-price,
  &.ask-bid-size {
    width: 30%;
    font-size: 1.2em;
  }

  &.margin-description,
  &.margin-date-valuation,
  &.order-valuation,
  &.volume {
    width: 40%;
    font-size: 1.2em;
  }

  &.high-low,
  &.open-close,
  &.pnl,
  &.price-shares {
    width: 30%;
    font-size: 1.2em;
  }

  &.actions {
    padding: 0.4rem 0;
    text-align: center;
    width: 100%;
  }

  > .value {
    display: block;
    text-align: right;
    white-space: nowrap;
  }
}

.quote-card-flag {
  background: #aaa;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 0;
  color: #fff;
  display: inline-block;
  font-size: 90%;
  height: 1.4em;
  line-height: 1.4em;
  margin-right: 0.4em;
  position: relative;
  text-align: center;
  vertical-align: baseline;
  width: 1.4em;

  &.symboloverview {
    bottom: 12.5px;
  }

  &.left {
    border-top-left-radius: 0;
    border-top-right-radius: 2px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 2px;
    margin-right: 0;
    margin-left: 0.4em;
  }

  &::after {
    border-top: 0.7em solid transparent;
    border-right: 0.35em solid transparent;
    border-bottom: 0.7em solid transparent;
    border-left: 0.35em solid #aaa;
    content: '';
    display: block;
    left: 100%;
    position: absolute;
    top: 0;
  }

  &.left::after {
    border-right-color: #aaa;
    border-left-color: transparent;
    content: '';
    left: auto;
    right: 100%;
  }

  &.ex-rights {
    background: #092;

    &::after {
      border-right-color: #092 !important;
    }
  }

  &.high,
  &.bid {
    background: #d00;
    font-size: 0.7em;
  }

  &.high::after,
  &.bid::after {
    border-left-color: #d00;
  }

  &.low,
  &.ask {
    background: #55f;
    font-size: 0.7em;
  }

  &.low::after,
  &.ask::after {
    border-left-color: #55f;
  }
}

.quote-card .value {
  > {
    .tag {
      color: rgba(0, 0, 0, 0.5);
      font-size: 0.8em;
      padding-left: 0.2em;
    }

    .pct {
      font-size: 0.8em;
    }
  }

  &.quote-ask-price > .tag {
    color: #55f;
  }

  &.quote-bid-price > .tag {
    color: #d00;
  }

  &.margin-type > .tag,
  &.open-date > .tag,
  &.order > .tag,
  &.pnl-valuation > .tag,
  &.quote-volume > .tag,
  &.quote-vwap > .tag {
    float: left;
  }
}

.quote-card-exrights-container {
  display: inline-block;
  margin-left: 5px;
}

.actions {
  text-align: center;

  &.add-scan-criteria, &.edit-scan-criteria {
    text-align: left;
    margin-top: 15px;
    margin-bottom: 0px;

    .add-criteria-link {
      margin: 0px;
      padding: 0px;
    }
  }

  > {
    a {
      border: none;
      margin: 0 0.2rem;
      max-height: 44px;
      cursor: pointer;
      color: #fff;
      font-size: 11px;
      padding: 1.1em;
      
      @media (max-width: $smallphone-max-breakpoint) {
        padding: 1.0em;
      }
    }

    a[disabled] {
      pointer-events: none;
    }

    a.my-scans-button {
      padding: 0px;
    }

    .details {
      color: darkgray;
    }

    .buy {
      background: #d84e4e;
      border: 2px solid #d84e4e;
      color: #fff !important;
      text-decoration: none !important;

      &:hover {
        background: #e27a7a;
        border: 2px solid #e27a7a;
      }
    }

    .buy-disabled {
      @extend .buy;
      background: #e98484 !important;
      border: 2px solid #e98484 !important;
      color: rgb(226, 226, 226) !important;
    }

    .sell {
      background: #3d9ad3;
      border: 2px solid #3d9ad3;
      color: #fff !important;
      text-decoration: none !important;

      &:hover {
        background: #77b8e0;
        border: 2px solid #77b8e0;
      }
    }

    .sell-disabled {
      @extend .sell;
      background: #60a6d1 !important;
      border: 2px solid #60a6d1 !important;
      color: rgb(226, 226, 226) !important;
    }

    .margin {
      &:hover {
        background: #faf1d0;
      }

      border: 2px solid #e1c561;
      background: #fff;
      color: #ad9951 !important;
    }
    
    .margin-disabled {
      @extend .margin;
      background: #fff !important;
      border: 2px solid #e6e0cc !important;
      color: rgb(177, 177, 177) !important;
    }

    .details {
      border: 2px solid #ccc;
      background-color: #eee;

      &:hover {
        background: #ccc;
      }

      color: #666 !important;
    }
  }
}

.btn-cmn {
  display: inline-block;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(1, 1, 1, 0.5);
  -moz-box-shadow: 0 1px 3px rgba(1, 1, 1, 0.5);
  -webkit-box-shadow: 0 1px 3px rgba(1, 1, 1, 0.5);
  text-decoration: none !important;
  font-weight: bold;

  &:hover,
  a {
    text-decoration: none;
  }
}

.btn-s {
  display: inline-block;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(1, 1, 1, 0.5);
  -moz-box-shadow: 0 1px 3px rgba(1, 1, 1, 0.5);
  -webkit-box-shadow: 0 1px 3px rgba(1, 1, 1, 0.5);
  font-weight: bold;
  vertical-align: middle;
  padding: 8px;
}

.marketboard-list-tabs .tab {
  background-color: #fafafa !important;
}

.monex-list-tabs .tab {
  border-radius: 5px 5px 0 0;
  width: 32%;
  max-width: 120px;
  min-width: max-content;
}

.search-list {
  padding-top: 0px !important;
}

.search-list-item.add-symbol > div > div {
  padding: 8px 10px 8px 8px !important;
  white-space: nowrap;
  display: block;
  overflow: hidden !important;
  margin: 0px;
  font-size: $normal-font !important;

  > {
    .add-symbol-button {
      margin-top: -20px;
    }

    .add-button {
      float: right;
      margin: 0px;
    }

    .formatted-exchange {
      font-size: $medium-font;
      line-height: 16px;
      color: #0000008a;
      white-space: nowrap;
      overflow: hidden !important;

      > span.short-name {
        margin-right: 0.5em;
      }
    }
  }
}

.white-svg-icon {
  display: inline-block;
}

.white-svg-label {
  color: #FFFFFF;
  display: inline-block;
  vertical-align: top;
}

.primary-text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-result {
  font-size: 0.9em;
  text-align: center;
  margin: 8px 8px;
}

.marketboard-heading {
  position: relative;
  top: 16px;
  height: 40px;
  padding-left: 12px;
}

.marketboard-list-item {
  height: 52px;
  left: 0px;
  top: 0px;
  padding: 0px;
  border-radius: 0px;
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.15);
  display: grid;
  grid-template-columns: 40px 1fr;
}

.marketboard-list-item-add-board {
  background: rgba(240, 230, 201, 0.2);
  border-top: none;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.15);
  height: 52px;
  font-family: "ヒラギノ角ゴシック ProN","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,"ＭＳ Ｐゴシック","MS PGothic",sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #333333;
  
  .disabled {
    opacity: 0.3;
  }
  
  > div {
    margin: 4px auto 0px auto;
  }
  > span {
    padding: 0px;
  }
}

.add-board-icon {
  > div {
    min-width: 0px !important;
  }
  > span {
    position: relative;
    top: -7px;
    margin-left: 5px;
  }
}

.marketboard-list-item-text {
  height: 52px;
  > div {
    > span {
      position: relative;
      top: 16px;
      margin-left: 5px;
      font-family: "ヒラギノ角ゴシック ProN","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,"ＭＳ Ｐゴシック","MS PGothic",sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      color: #333333;
    }

  }
}

.marketboard-list-item-delete-icon {
  padding: 0px !important;
  min-width: 0px !important;
  visibility: visible !important;
  
  > div {
    padding: 6px 0px 0px 10px;
  }
  
  .disabled {
    opacity: 0.3;
  }
}

/* Reduce the default padding directly applied by Material UI (16px) and
   * increase the left padding in order to align the left edge of the add icon
   * with the left edge of the marketboard titles: */

.marketboard-edit-symbol-row {
  background: #fff;
  user-select: none;

  &.filled > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis !important;

    > span.short-name {
      margin-right: 0.5em;
    }
  }

  &.empty {
    padding-top: 4px !important;
    padding-bottom: 4px !important;

    .add-button {
      opacity: 0.75;
    }
  }

  .drag-handle,
  .delete-button,
  .undo-button {
    top: 4px !important;
  }
}

.drag-item {
  box-shadow: 0 8px 8px -4px rgba(0, 0, 0, 0.2);
}

@media (max-width: 639px) {
  .quote-card-column-group {
    width: 100%;

    &.current {
      &.position {
        margin-bottom: 15px;
      }
    }
  }
}

@media (min-width: 640px) and (max-width: 959px) {
  .quote-card-column-group {
    &.current,
    &.historical {
      width: 50%;
    }

    &.actions,
    &.memo {
      width: 100%;
    }

    &.actions {
      &.position {
        margin-top: 25px;
      }
    }
  }

  .quote-cash-margin-header-left {
    width: 18% !important;
  }
  
  .quote-cash-margin-header-center {
    width: 17% !important;
    padding-left: 1px;
  }

  .quote-cash-margin-header-right {
    float: none !important;
    width: 15% !important;
    text-align: end;
  }

  .historical {
    &.margin {
      margin-top: -9px;
    }
  }
}

.quote-card.expanded {
  max-height: 26rem;
}

@media (min-width: 960px) {
  .quote-card-column-group {
    &.current,
    &.historical {
      width: 33%;
    }

    &.current {
      &.position {
        margin-bottom: 15px;
      }
    }

    &.actions {
      width: 34%;
      &.position {
        width: 33%;
      }
    }

    &.memo {
      width: 100%;
    }
  }

  .quote-cash-margin-header-left {
    width: 13% !important;
  }
  
  .quote-cash-margin-header-center {
    width: 10% !important;
    padding-left: 5px;
  }

  .quote-cash-margin-header-right {
    float: none !important;
    width: 10% !important;
    text-align: end;
  }
}

svg,
.material-icons {
  color: rgba(0, 0, 0, 0.87);
}

.short-name {
  margin-right: 0.5em;
}

.list-item-no-padding {
  padding: 0 !important;

  > div {
    padding: 0 !important;

    > {
      svg {
        height: 48px;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 17px;
        opacity: 0.5;
        width: 24px;
        margin-right: 7px;
      }

      span {
        padding-left: 10px;
      }
    }
  }
}

.drag-handle {
  .user.marketboard-list [class^='MuiTypography-root-'] {
    padding-left: 7px !important;
  }

  svg {
    margin-left: 5px;
  }
}

.marketboard-edit-symbols > {
  div {
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 0;
  }

  li {
    padding: 0 !important;
  }
}

.search-list-item.add-symbol {
  padding-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.marketboard-edit-symbol-row.filled > div > p {
  margin-top: -4px;
}

.search-list-item.add-symbol > div {
  > p {
    margin-top: -4px;
  }

  margin-right: 0 !important;
}

.no-content.empty {
  font-size: 20.8px;
}

.left-button > a > button {
  padding-top: 11px;
}

.symbol-note-icon {
  width: 20px;
  height: 13px;
  padding-right: 3px;
  padding-top: 2px;
}

.symbol-note {
  margin-top: 5px;
  border: 1px solid #D7D7D7;
}

.symbol-note-header {
  top: 377px;
  left: 15px;
  text-align: right;
  background: #E0E0E0 0% 0% no-repeat padding-box;
  border: 1px solid #D7D7D7;
  border-radius: 3px 3px 0px 0px;
  opacity: 1;
}

.symbol-note-header-left {
  float: left;
  font-size: 12px;
  padding-left: 5px;
  letter-spacing: 0px;
  color: #616161;
  opacity: 1;
  margin-top: 1px;
}

.symbol-note-header-right {
  font-size: 12px;
  text-align: right;
  letter-spacing: 0px;
  color: #616161;
  opacity: 1;

  &.empty {
    padding-right: 30px;
    padding-top: 1px;
  }

  &.value {
    padding-top: 2px;
  }

  > .text {
    padding-right: 5px;
  }

  > .arrow {
    float: right;
    color: #9E9E9E;
    padding-right: 1px;
  }
}

.symbol-note-body {
  font-size: 12px;
  top: 399px;
  left: 15px;
  border-radius: 3px;
  opacity: 1;
  padding: 5px;
}

/****** BEGIN Marketboard PRO *******/
.ag-root-wrapper {
  border-bottom: 0px !important;

  // Added so that symbol search results are shown over AG grid itself
  overflow:visible !important;
}

.ag-cell-value {
  &.gain5 { border-left: 5px solid #ff3333 !important }
  &.gain4 { border-left: 5px solid #ff5959 !important }
  &.gain3 { border-left: 5px solid #ff7f7f !important }
  &.gain2 { border-left: 5px solid #ffa6a6 !important }
  &.gain1 { border-left: 5px solid #ffcccc !important }
  &.unch { border-left: 5px solid #f1f1f1 !important }
  &.loss1 { border-left: 5px solid #cce1ff !important }
  &.loss2 { border-left: 5px solid #a6cbff !important }
  &.loss3 { border-left: 5px solid #7fb5ff !important }
  &.loss4 { border-left: 5px solid #599eff !important }
  &.loss5 { border-left: 5px solid #3388ff !important }
}

.ag-tool-panel-wrapper {

  display: inline !important;
  background-color: white !important;

  &.ag-hidden {
    display: none!important;
  }

  /* Matrix Styles shown on Pro Marketboard Side Bar */
  .matrix.section {
    width: 100%;
    font-family: "Hiragino Kaku Gothic ProN", Meiryo, "MS PGothic", sans-serif;
    font-size: 12px;
    padding-right: 4px;
    
    .matrix-row {
      line-height: 28px;

      &.title {
        padding: 0px;
        .ask, .price, .bid {
          color: rgb(113, 113, 113)
        }
      }
      .number {
        font-family: "Hiragino Kaku Gothic ProN", Meiryo, "MS PGothic", sans-serif;
      }
      .ask {
        color: blue;
        width: 33%;
        padding-right: 0px;
      }
      .price {
        width: 34%;
        padding-right: 0px;
      }
      .bid {
        color: #e01515;
        width: 33%;
        padding-right: 0px;
      }

      .ask, .bid {
        .number {
          padding-right: 5px;
        }
      }
      .ask.allow-buy:hover,
      .bid.allow-sell:hover {
        &:after
        {
            margin-top: -28px;
        }
      }
    }
  }
}

/****** END Marketboard PRO *******/

/******* BEGIN SymbolOverview *******/
/************* BEGIN Miscellaneous Styles *************/

.symbol-overview-title {
  max-width: 1500px;
  margin: auto;
  margin-bottom: 10px;
}

.symbol-overview-pro-header {
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 30px;
}

.symbol-overview-pro-header-edit-button {
  float: right;
  position: relative;
  top: 45px;
  z-index: 1;
}

.symbol-overview-trade-bar {
  width: 500px;
  display: inline-block;
  margin-top: 7px;
}

.symbol-overview-page-subtitle {
  clear: both;
}

.symbol-overview-ticker {
  min-height: 50px;
  min-width: 50px;
}

.symbol-details-settings-icon {
  float: right;
  margin-top: 5px;
  padding-right: 5px;
}

.container:after {
  /*clear float*/
  content: '';
  display: table;
  clear: both;
}

.section {
  /* float: left; */
  width: 24.999%;
  box-sizing: border-box;
}

.symbol-detail-link-icon {
  padding-right: 8px;
  height: 20px !important;
  width: 20px !important;
  vertical-align: middle;
  color: #555555;
}

.symbol-detail-link {
  color: $link;
}

.symbol-detail-net-change-value {
  display: block !important;
  padding-bottom: 3px;
  text-align: left;
}

.symbol-fundamentals-margin-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: row;
  grid-auto-columns: auto;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  justify-items: center;

  padding-left: 5px;
  padding-right: 5px;

  .symbol-fundamentals-margin-container-value {
    background-color: #e6e6e6;
    display: inline-block;
    font-size: 0.75rem;
    border: 3px;
    width: 100%;
    text-align: center;

    span {
      vertical-align: middle;
      display: inline-block;
      padding: 5px;
    }
  }
}

.symbol-stats-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto-fit);
  grid-auto-rows: auto;
  grid-gap: 5px;
  justify-items: center;
  padding: 11px 5px 5px;
}

.data-panel {
  width: 100%;
  background-color: #fafafa;
  display: inline-block;
  margin-bottom: 5px;
  min-height: 52px;
  border-radius: 4px;
}

.data-panel-header {
  height: 24px;
  background-color: #f6f6f6;
}

.data-panel-header-label {
  float: left;
  font-size: 13px;
  padding-left: 3px;
}

.data-panel-header-timestamp {
  float: right;
}

.data-panel-value {
  padding-left: 5px;
  font-size: 18px;
}

.symbol-details-container {
  max-width: 1500px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

.symbol-fundamentals-section,
.symbol-external-links-section,
.symbol-detail-section {
  display: flex;
  flex-direction: column;
}

.symbol-detail-section-title {
  text-indent: 5px;
  display: block;
  font-size: 1.5em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.symbol-detail-page-title {
  border-bottom: 3px solid #d0d0d0;
  margin-top: 5px;
  margin-bottom: 5px;
  text-indent: 5px;
  display: block;
  font-size: 1.5em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.symbol-detail-net-change {
  display: inline-block;
  padding-left: 10px;

  > .number {
    &.negative {
      color: $loss;
    }

    &.positive {
      color: $gain;
    }
  }
}

.symbol-details-flash-wrapper {
  display: inline-block;
}

.symbol-detail-page-subtitle {
  > {
    .symbol-details-nikkei-flag,
    .symbol-details-status-flag {
      display: inline;
      padding-left: 10px;
    }
  }

  color: rgba(0, 0, 0, 0.5);
  font-size: 14.36px;
  margin-left: 5px;
  margin-top: 2px;
}

.symbol-detail-price-data {
  font-weight: bold;
  margin-left: 5px;
  text-align: center;
}

.symbol-details-last-price {
  display: inline-block;
  font-size: 48px;
  font-weight: normal;
  line-height: 48px;
  margin: 3px;
}

.symbol-details-note {
  padding-left: 5px;
  padding-right: 5px;
}

.section-header {
  margin-top: 10px;
  background-color: #e0e0e0;
  height: 26px;
  font-weight: bold;
  padding-left: 8px;
  padding-top: 4px;
  vertical-align: middle;
}

/************* END Miscellaneous Styles *************/

/************* BEGIN TradeSpeedDial Styles *************/

.trade-speed-dial {
  position: fixed !important;
  bottom: 20px;
  right: 20px;

  > button {
    background-color: #ffdd3f;
    color: #000000;
    font-weight: bold;

    &:hover {
      background-color: #ffdd3f;
      color: #000000;
      font-weight: bold;
    }
  }
}

.trade-speed-dial-cash-buy {
  background-color: #d84e4e !important;
  color: #ffffff !important;
}

.trade-speed-dial-cash-sell {
  background-color: #3d9ad3 !important;
  color: #ffffff !important;
}

.trade-speed-dial-margin-buy {
  color: #ad9951 !important;
  border: 2px solid #e1c561 !important;
  background: #ffffff !important;
}

.trade-speed-dial-margin-refund {
  color: #ffffff !important;
  background: #dbab00 !important;
}

.trade-speed-dial-button {
  font-weight: bold;
  font-size: 12.5px !important;
  margin: 6px !important;
}

#SpeedDial-actions {
  margin-bottom: -44px !important;
}

/************* END TradeSpeedDial Styles *************/

/************* BEGIN SymbolChart Styles *************/

.highcharts-tooltip {
  .point-timestamp {
    border-bottom: 1px solid #95a3b5;
    color: #727c8b;
    font-weight: bold;
    margin-bottom: 2px;
    padding: 1px;
    text-align: center;
  }

  .point-value {
    font-weight: bold;
    text-align: right;
  }
}

/* This was added so that Highcharts does not
     attempt to display the tooltip behind the header */

.highcharts-tooltip-container {
  margin-top: 50px;
}

.symbol-details-chart-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.symbol-details-chart-button {
  font-size: 11px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  border-top-right-radius: 2px !important;
  border-top-left-radius: 2px !important;
  color: #193a9e !important;
  background: #e0e0e0 !important;
  min-width: 10px !important;
  width: 40px !important;
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.symbol-details-chart-button-selected {
  font-size: 11px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  border-top-right-radius: 2px !important;
  border-top-left-radius: 2px !important;
  color: black !important;
  background: #e1c561 !important;
  min-width: 10px !important;
  width: 40px !important;
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.chart-tab {
  background-color: #fafafa !important;
  min-width: 35px !important;
}

.settings-chart-style-section {
  padding-top: 15;
  width: 100%;
}

.settings-chart-style-title {
  float: left;
  margin-top:5px;
  padding:5px;
}

.settings-label {
  display: inline-block;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-top: 15px;
}

.settings-option {
  float: right;
  padding-right: 0px;
  margin-right: 10px;
  margin-top: 5px;
}

.settings-chart-style-div-select {
  float: right;
  padding-right: 15;
  margin-top: -10;
  margin-right: 15px;
}

.settings-chart-style-select {
  min-width: 130;
  text-align: right;
}

.settings-inner-container {
  margin-top: 38px;

  ul {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.settings-inner-container.symbol-setting {
  margin-top: 0px;
}

.settings-chart-display-tooltip-toggle {
  display: inline-block;
  width: 40px;
  float: right;
  margin-top: -15px;
  padding-right: 10px;
}

.settings-chart-indicators-title {
  padding-left: 5px;
  font-size: 20px;
}

.symbol-details-chart-container {
  max-width: 1400px;
  display: flex;

  @media (max-width: $smallphone-landscape-max-breakpoint) {
    width: 100%;
  }

  @media (min-width: 601px) and (max-width: $portrailt-tablet-max) {
    width: 60%;
  }

  @media (min-width: $landscape-tablet-min) and (max-width: $landscape-tablet-max) {
    width: 65%;
  }

  @media (min-width: $desktop-min) {
    width: 75%;
  }
}

.symbol-details-chart-inner-container {
  width: 100%;
}

.symbol-details-chart-no-data {
  font-size: 12px;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 50px;
}


.symbol-margin-fundamentals-information-section {
  margin-top: 10px;
  width: 100%;

  @media (max-width: $smallphone-max-breakpoint) {
  }

  /* Phones */
  /* For iPhone 5/SE We need the extra space for the stats container */
  @media (max-width: $phone-max-breakpoint) {
  }

  @media (max-width: $smallphone-landscape-max-breakpoint) {
  }

  /* Reclaim the space taken by the Nikkei 225 flag when large enough */
  @media (min-width: 700px) {
  }

  /* Portrait Tablets */
  @media (min-width: 601px) and (max-width: portrailt-tablet-max) {
    width: 100%;
  }

  /* Landscape and High-Res Tablets */
  @media (min-width: 813px) and (max-width: $landscape-tablet-max) {
    width: 38%;
    margin-right: 10px;
  }

  /* Desktops */
  @media (min-width: $desktop-min) {
    margin-right: 10px; 
    width: 27%;
  }
}

/************* END SymbolChart Styles *************/

/************* BEGIN SymbolMatrix Styles *************/

.matrix {
  margin-bottom: 10px;
}

.matrix-row {
  width: 100%;

  .ask,
  .price,
  .bid {
    width: calc(32.7% - 1px);
    display: inline-block;
    text-align: center;
    padding-right: 1%;
  }

  .ask.allow-buy:hover,
  .bid.allow-sell:hover {
    cursor: pointer;
    outline-offset: -1px;
    &:after
    {
      float: left;
      margin-top: -23px;
      margin-left: 5px;
      height: 0;
    }

    >.flag {
      opacity: 0;
    }
  }
  .flag {
    float: left;
    margin-left: 7px;
  }
  .flag:hover {
    outline: 1px solid #3377ff;
  }
  .ask.allow-buy:hover {
    background: #ffebeb;
    outline: 1px solid #fe0000;
    &:after
    {
      font-weight: bold;
      color: #fe0000;
      content: '\8cb7';
    }
  }
  .bid.allow-sell:hover {
    background: #eff4ff;
    outline: 1px solid #3377ff;
    &:after
    {
      font-weight: bold;
      color: #3377ff;
      content: '\58f2';
    }
  }

  &:nth-child(odd) {
    background-color: #f2f2f2;

    .price {
      background-color: #e7e9ec;
    }
  }

  &.pre-order {
    background-color: rgba(252, 252, 252, 1);

    .price {
      background-color: #e7e9ec;
      font-size: 0.875rem;
    }
  }

  &.title {
    font-size: 0.875rem;
    text-align: center;
    vertical-align: middle;
    margin-top: -1px;
    background-color: rgba(252, 252, 252, 1);

    > {
      .price,
      .ask,
      .bid {
        color: rgba(252, 252, 252, 1) !important;
        font-size: 0.875rem;
        text-align: center;
        vertical-align: middle;
        padding-top: 1px;
        background-color: #999999;
      }
    }
  }

  .price.current {
    background-color: yellow;
  }

  .price.above-last {
    border-bottom: solid 1px yellow;
  }

  .price.below-last {
    border-top: solid 1px yellow;
  }

  .ask,
  .bid {
    text-align: right;
  }

  .ask .empty.hidden,
  .bid .empty.hidden {
    display: none;
  }

  .ask {
    color: #0055ff;
  }

  .bid {
    color: #ff0000;
    padding-right: 2px;
  }
}

.matrix-container {
  width: 100%;
}

/************* END SymbolMatrix Styles *************/

/************* BEGIN SymbolNews Styles *************/

.section.symbol-news-section {
  display: flex;
  width: 49%;
}

.symbol-headlines {
  width: 100%;
}

/************* END SymbolNews Styles *************/

/************* BEGIN SymbolExternalLinks Styles *************/

.symbol-stats-table {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-gap: 20px;
  grid-row-gap: 3px;
  justify-items: center;
}

.symbol-stats-row {
  width: 100%;
  display: inline-block;
  border-bottom: 2px solid #d0d0d0;
  margin-bottom: 5px;
}

.symbol-stats-table {
  padding: 5px;
}

.symbol-stats-label {
  float: left;
  color: rgba(0, 0, 0, 0.5);
  font-size: 15.36px;
  position: relative;
  bottom: 0px;
}

.symbol-stats-value {
  text-align: right;
  font-size: 19.2px;
}

.symbol-stats-value-text {
  text-align: right;
  font-size: 17.3px;
}

.symbol-stats-timestamp,
.symbol-stats-date,
.data-panel-header-label {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13.36px;
  margin-right: 3px;
}

/************* END SymbolExternalLinks Styles *************/

/************* BEGIN SymbolOverview Media Queries *************/
/* Hide certain chart tabs on phones too small */
@media (max-width: $smallphone-max-breakpoint) {
  .chart-tab.optional-tab {
    display: none;
  }
}

/* Reclaim the space taken by the Nikkei 225 flag when large enough */
@media (min-width: 700px) {
  .symbol-detail-page-subtitle {
    position: absolute;
  }
}

/* Phones */
/* For iPhone 5/SE We need the extra space for the stats container */
@media (max-width: $phone-max-breakpoint) {
  .symbol-stats-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: $smallphone-landscape-max-breakpoint) {
  .matrix.section {
    width: 100%;
    margin: auto;
  }

  .symbol-news-section.section,
  .symbol-fundamentals-section,
  .symbol-external-links-section {
    width: 100%;
  }

  .matrix-rows {
    padding-top: 8px;
    margin-bottom: 10px;
  }

  .symbol-stats-table {
    grid-template-columns: 1fr;
  }
}

/* Portrait Tablets */
@media (min-width: 601px) and (max-width: $portrailt-tablet-max) {
  .matrix.section {
    width: 40%;
    padding-left: 4px;
  }

  .symbol-news-section.section,
  .symbol-fundamentals-section,
  .symbol-external-links-section {
    width: 100%;
  }

  .symbol-stats-container {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .symbol-stats-table {
    grid-template-columns: 1fr 1fr;
  }
}

/* Landscape and High-Res Tablets */
@media (min-width: $landscape-tablet-min) and (max-width: $landscape-tablet-max) {
  .matrix.section {
    width: 35%;
    padding-left: 4px;
  }

  .symbol-news-section.section {
    width: 60%;
  }
  .symbol-fundamentals-section,
  .symbol-external-links-section {
    width: 100%;
  }

  .symbol-fundamentals-margin-container {
    grid-template-columns: repeat(4, 1fr);
  }

  .symbol-stats-container {
    grid-template-columns: 1fr 1fr;
  }

  .symbol-stats-table {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/* Desktops */
@media (min-width: $desktop-min) {
  .symbol-details-chart-container {
    width: 75%;
  }

  .matrix.section {
    width: 25%;
    padding-left: 4px;
  }

  .symbol-news-section.section {
    width: calc(72% - 10px);
  }

  .symbol-fundamentals-section {
    // width: 25%;
  }

  .symbol-external-links-section {
    width: 100%;
  }

  .symbol-stats-table {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

/************* BEGIN SymbolSummary Margin Information *************/

.symbol-margin-information-section {
  width: 100%;
  margin-bottom: 35px;
}

.symbol-margin-information-title {
  font-size: 0.875rem;

  > .title {
    float: left;
    padding-left: 10px;
    font-weight: bold;
    font-size: $small-font;
  }

  > .date {
    float: right;
    padding-right: 10px;
    margin-bottom: 10px;
    font-size: $small-font;
  }
}

$margin-information-cell-color: $light-grey;
$margin-information-background-color: #ededed;

.symbol-margin-information-table-cell-title {
  border-left: 1px solid $margin-information-cell-color;
  border-bottom: 1px solid $margin-information-cell-color;
  display: table-cell;
  text-align: left;
  font-size: $small-font;
  background: $margin-information-background-color 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-left: 10px;
}

.symbol-margin-table-cell {
  border-left: 1px solid $margin-information-cell-color;
  border-bottom: 1px solid $margin-information-cell-color;
  display: table-cell;
  padding: 3px;
  min-width: 40px;
}

.symbol-margin-table-cell-time {
  border-bottom: 1px solid $margin-information-cell-color;
  display: table-cell;
  min-width: 30px;
  font-size: $small-font;
  &.last-column {
    border-right:1px solid $light-grey;
  }
}

.symbol-margin-information-table-cell-value {
  font-weight: bold;
  text-align: right;
  padding-right: 8px;
  &.session-detail {
    padding-right: 3px;
  }  
  &.last-column {
    border-right:1px solid $light-grey;
  }  
}

/************* END SymbolSummary Margin Information *************/

/************* END SymbolOverview Media Queries *************/

/************* BEGIN SymbolIndicator Styles *************/

.symbol-details-indicators-fundamental-analysis-container {
  max-width: 1500px;
  margin: auto;
}

.symbol-details-indicators-fundamental-analysis-section {
  margin-bottom: 35px;
}

.symbol-indicators-title {
  margin-top: 17px;
}

.symbol-indicators-fundamental-anaylis-title {
  min-width: 63px;
}

.symbol-indicator-fundamental-analysis-details {
  margin-top: 17px;
}

.symbol-indicator-table-cell-title {
  border-left: 1px solid $light-grey;
  border-bottom: 1px solid $light-grey;
  display: table-cell;
  padding: 3px;
  text-align: left;
  font-size: 13px;
  background: #ededed 0% 0% no-repeat padding-box;
  opacity: 1;
}

.symbol-indicator-table-cell-value {
  text-align: right;
  border-left: 1px solid $light-grey;
  padding: 3px;
}

.analyst-consensus-table {
  text-align: center;
  width: 100%;
  font-size: 13px;

  &.title {
    margin-top: 22px;
  }
}

.analyst-consensus-table-row {
  padding: 6px 10px 6px 12px;
  display: flex;
  flex-direction: row;
}

.analyst-consensus-table-row {
  &.border-first {
    border-top: $light-grey 1px solid;
    border-bottom: $light-grey 1px solid;
  }

  &.border-bottom {
    border-bottom: $light-grey 1px solid;
  }

  &.title {
    background: #ededed 0% 0% no-repeat padding-box;
  }
}

.analyst-consensus-table-row-data {
  display: flex;
  flex: 1 1;
  flex-direction: row;
}

.analyst-consensus-table-row-column-header {
  flex-grow: 1;
  text-align: left;
}

.analyst-consensus-table-row-value {
  float: right;
  padding-right: 8px;
}

.symbol-indicator-score-analysis-header {
  margin-top: 17px;
  min-width: 286px;
  height: 63px;
  text-align: center;
  margin-bottom: 15px;

  > .title {
    font-size: $medium-font;
    font-weight: bold;
    vertical-align: bottom;
    line-height: 62px;

    &.title1 {
      padding-right: 10px;
    }
  }

  > .value {
    font-weight: bold;
    font-size: 40px;

    &.value1 {
      padding-right: 15px;
    }

    &.value2 {
      color: #9e9e9e;
      padding-left: 10px;
    }
  }
}

.score-analysis-row {
  margin-bottom: 18px;

  > .label {
    border-bottom: 1px solid #d2d7da;
    display: inline-block;
    margin-left: 10px;
    font-size: 15px;
  }

  @media (max-width: 500px) {
    > .label {
      width: 36%;
    }
  }

  @media (min-width: 501px) and (max-width: 699px) {
    > .label {
      width: 45%;
    }
  }

  @media (min-width: 700px) and (max-width: 1023px) {
    > .label {
      width: 47%;
    }
  }

  @media (min-width: 1024px) {
    > .label {
      width: 50%;
    }
  }

  > .value {
    padding-left: 7px;
    display: inline;
    font-weight: bold;
    font-size: 15px;
  }

  > .thermometer {
    margin-right: 10px;
    width: 45%;
    margin-left: 7px;
    border: 1px solid black;
    float: right;
    margin-top: 3px;

    > .company {
      background-color: #f9c716;
      height: 8px;
    }

    > .line {
      background-color: #e0e0e0;
      height: 1px;
    }

    > .sector {
      background-color: #bdbdbd;
      height: 8px;
    }
  }
}

/************* END SymbolIndicator Styles *************/

/******* END SymbolOverview *******/

/******* BEGIN Settings *******/

.settings-chart-container {
  width: 100%;
}

.settings-chart-indicators-container {
  background-color: #f7f7f7;
  padding-right: 8px;
  border-bottom: #e6e6e6 .5px solid;
  border-top: #f2f2f2 1px solid;
  padding-left: 12px;
  outline: 1px solid #e6e6e6;
}

.settings-title-bar {
  left: 0;
  right: 0;
  position: fixed !important;
  top: 0px;
  background-color: white;
  
  /* height of the fixed yellow bar */
  z-index: 1030;
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.2);
  clear: both;
  height: 40px;
  line-height: 49px;
  transition: box-shadow 100ms linear;
  text-align: center;
  font-size: 17px;
}

.symbol-settings-title-bar {
  left: 0;
  right: 0;
  top: 0px;
  border: 1px #E2E2E2;
  background-color: #FAFAFA;
  
  /* height of the fixed yellow bar */
  z-index: 1030;
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.2);
  line-height: 49px;
  transition: box-shadow 100ms linear;
  text-align: center;
  font-size: 19px;
}

.settings-card {
 
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px;
 
  margin-left: 0px;
  overflow: hidden;
 
  position: relative;
  -webkit-transition: max-height 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: max-height 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  min-width: 312px;
  list-style-type: none;

}
.settings-card-contents {
  padding-left: 8px;
}

.chart-style-section {
  background-color: #DCDDDD;
  padding: 4px 4px 4px 12px;
}

.chart-style-section.chart-style-section-highlight {
  font-weight: bold;
}

.settings-chart-indicators-subtitle {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background: #f2f2f2;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.9em;
  line-height: 1.2rem;
  margin-left: -8px;
  padding: 4px 4px 4px 12px;
  font-weight: bold;
}

.settings-chart-indicators-properties {
  padding-bottom: 7px;
  margin-top: 7px;
  padding-top: 10px;

}

.settings-chart-indicators-sma-input {
  width: 90px;
  margin-left: 20px !important;
  margin-top: -5px !important;
}

.settings-chart-indicators-sma-add {
  display: inline-block;
  float: right;
  margin-top: -15px;

  .settings-chart-indicators-add-text {
    padding-top: 15;
    padding-left: 5;
  }
}

.settings-chart-ave-dev {
  margin-bottom: 10px;
}

.settings-chart-ave-dev-add {
  display: inline-block; 
  width: 82%
}

.settings-chart-indicators-sma-period {
  width: 50px;
  display: inline-block;
}

.settings-chart-indicators-macd-period {
  width: 75px;
  height: 35px;
  display: inline-block;
}

.settings-chart-indicators-rci-period {
  width: 75px;
  height: 35px;
  display: inline-block;
}

.settings-chart-indicators-sma-remove,
.settings-chart-indicators-sma-input-container {
  display: inline-block;
}

.settings-chart-indicators-sma-remove {
  float: right;
  margin-top: -15px;
}

.settings-chart-indicators-list-title-even {
  background-color: #f5f5f5;
  height: 30px;
  width: 100%;
}

.settings-chart-indicators-list-title-odd {
  background-color: white;
  height: 30px;
  width: 100%;
}

.settings-chart-indicators-list-title-even,
.settings-chart-indicators-list-title-odd {
  padding-left: 5px;
  font-weight: bold;
  display: inline-block;
}

.settings-chart-indicators-list-add {
  display: inline-block;
  float: right;
  margin-top: -10px;
  padding-right: 5px;
}

.settings-chart-indicators-cci-period {
  width: 75px;
  height: 35px;
  display: inline-block;
}

.refine-icon {
  width: 25px;
  height: 25px;
}

.gnavi-mobile .breadcrumb-sp {
  margin-bottom: 0;
  padding: 0 8px;
  height: 30px;
  border-radius: 0;
  background-color: #ffffcf;
  font-size: $small-font;
  line-height: 30px;
  letter-spacing: -6px;
  white-space: nowrap;
  width: 100%;
}

#gnavi-mobile-help {
  display: none;
}

#gnavi-mobile-help-bar {
  height: 30px;
  float: right;
  width: 100%;
  text-align: right;
  
  &.desktop {
    height: 40px;
    margin-right: 15px;
    width: 300px;
  }
  
  .gnavi-mobile-help-icon {
    margin-top: -3px;
    padding-right: 5px;
    text-decoration: none;
  }

  .gnavi-mobile-help-link {
    font-size: $medium-font;
    color: #006ccc;
    margin-right: 10px;
    font-weight: bold;
    float: right;
    margin-top: 3px;

    &.desktop {
      margin-top: 5px;
      font-weight: normal;
    }
  }

  .gnavi-mobile-sometaro-link {
    &:before {
      background: url(https://mxp2.monex.co.jp/pc/static/image/common/ico-update_01.png) no-repeat 0 5px;
      content: '';
      width: 20px;
      height: 20px;
      display: inline-block;
      vertical-align: -2px;
      margin-right: 5px;
      margin-top: 4px;
    }
  }
}

.section-desktop-help-bar {
  display: flex;
  justify-content: right;
  height: 25px;
}

#gnavi-desktop-help-bar {
  height: 40px;
  margin: auto;
  max-width: 980px;
}

.gnavi-desktop {
  overflow: hidden;
  height: 36px;
  margin-left: 20px;
  .breadcrumb {
    font-size: 13px;
    line-height: 16px;
    float: left;
    overflow: hidden;
    li {
      float: left;
      margin: 0 10px 0 0;
      a {
        margin: 0 10px 0 0;
      }
    }
  }
  .link {
    font-size: 13px;
    line-height: 16px;
  }
}

/******* END Settings *******/

/******* START GLOBAL NEWS **/

.container.global-news {
  max-width: 1500px;
  margin: auto;

  // @media (min-width: 1201px) {
  //   .news-search {
  //     float: left;
  //     width: 20%;
  //     margin-top: 40px;

  //   }
  //   .global-news-articles {
  //     float: right;
  //     margin-top: 40px;
  //     width: 78%;
  //   }
  //   .section-header {
  //     clear: both;
  //     position: absolute;
  //     width:99%;
  //     max-width: 1500px
  //   }
  // }
}
.news-search {
  padding-left: 5px;

  .news-search--provider-container {
    margin-left: 12px;
  }

  .news-search-container {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 13px;
    box-sizing: border-box;

    div[class$='-item'] {
      width: calc(100% - 28px);
    }

    div[class$='-label'] {
      width: calc(100% - 18px);
    }

    .search-box {
      background-color: unset;
    }

    .news-search--label {
      padding-top: $normal-font;
      font-size: $normal-font;
      border-bottom: 2px solid #e0e0e0;
      margin-left: 8px;
      margin-bottom: 10px;
    }

    .news-search--title {
      font-size: 16px;
      background-color: #e0e0e0;
      height: 26px;
      margin-left: -5px;
      font-weight: bold;
      padding-left: 8px;
      padding-top: 4px;
      margin-top: 15px;
      margin-bottom: 13px;
      vertical-align: $medium-font;
      &.desktop {
        font-size: $small-font;
      }
    }

    .news-provider--label {
      padding-bottom: 5px;
      margin-left: -3px;
      font-size: 13px;
    }

    .news-search--input {
      font-size: $normal-font;
      margin-top: -8px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #9e9e9e;
      opacity: 1;
      padding-inline-start: 8px;
      &.desktop {
        margin-left: -12px;
        width: 183px;
      }
    }

    .news-search--providers {
      margin-left: -6px;

      .selected {
        height: 27px;
        border: 1px solid $green;
        background-color: white;
        border-radius: 16px;
        margin: 2.5px;
        padding-right: 7px;
        &.desktop {
          border-radius: 6px;
        }

        svg {
          line-height: $small-font;
          color: $green;
          height: unset;
          font-size: $normal-font;
          margin-left: 8px;
        }
      }

      .un-selected {
        height: 27px;
        border: 1px solid $light-grey;
        background-color: white;
        border-radius: 16px;
        margin: 2.5px;
        padding-right: 7px;
        &.desktop {
          border-radius: 6px;
        }

        svg {
          color: $light-grey;
          height: unset;
          font-size: $normal-font;
          margin-left: 8px;
        }
      }
    }
  }
}

/******* END GLOBAL NEWS ****/

/******* BUTTON STUFF ******/
.button-selection-group {
  padding-left: 3px;
  padding-right: 1px;

  .selected {
    border: 1px solid $green;
    background-color: white;
    border-radius: 16px;
    margin: 2.5px;

    &:hover, &:focus {
      background-color: white;
    }

    svg {
      line-height: $small-font;
      color: $green;
      height: unset;
      font-size: $normal-font;
      margin-left: 8px;
    }
  }

  .un-selected {
    border: 1px solid $light-grey;
    background-color: white;
    border-radius: 16px;
    margin: 2.5px;

    &:hover, &:focus {
      background-color: white;
    }

    svg {
      color: $light-grey;
      height: unset;
      font-size: $normal-font;
      margin-left: 8px;
    }
  }
}

/******* END BUTTON ******/

/****** Start Hotlist ******/

.hotlist-container {
  max-width: 1500px;
  margin: auto;
}

.hotlist_header {
  display: block;
  background: #f5f5f5;
  color: #333333;
  font-size: $medium-font;
  line-height: 1.2rem;
  margin-left: 0px;
  width: 100%;
  height: 32px;
}

.hotlist_header__container {
  min-height: 60px;
  border-bottom: 2px #e1c561 solid;
}

.hotlist_header__inner_container {
  font-weight: bold;
  font-size: 0.875rem;
  padding-left: 20px;
}

.hotlist_header__text_container {
  display: inline-block;
  padding-top: 12px;
  padding-bottom: 12px;
  max-width: 80%;
}

.hotlist_header__subtext_container {
  font-weight: normal;
}

.hotlist_header__main_filter {
  padding-right: 20px;
  display: inline-block;
}

.hotlist_header__secondary_filter {
  display: inline-block;
}

.hotlist_header__filter_button {
  position: relative;
  float: right;
  max-height: 45px;
}

.hotlist_header__title {
  float: left;
  padding: 9px 4px 9px 16px;
}

.hotlist_header__results {
  padding-right: 10px;
  display: inline-block;
}

.hotlist_header__sort {
  float: right;
  margin-top: -3px;
  margin-bottom: -9px;
}

.hotlist_header__sortName {
  padding: 0px 15px 0px 0px;
  font-size: $medium-font;
  color: #0055cc;
}

.hotlist_values {
  border-bottom: $light-grey 1px solid;
  padding: 4px;
  padding-left: 12px;
  padding-right: 10px;
}

.hotlist_settings_category {
  border-bottom: $light-grey 1px solid;
  padding: 4px;
  padding-left: 12px;
  padding-right: 10px;
  width: 100%;
}

.hotlist_settings__row {
  margin-left: 4px;
  margin-right: 4px;

  &.one {
    font-size: $normal-font;
    padding: 2px 2px 0px 2px;
  }

  &.two > span {
    font-size: $medium-font;
    padding: 0px 2px 2px 2px;
  }
}

.hotlist_settings_category--name {
  font-size: $normal-font;
  color: #333333;
  font-weight: bold;
}

.hotlist_settings_category--selected {
  color: #9e9e9e;
}

.hotlist_selected_item_check {
  position: absolute;
  left: 5px;
  color: #2EB975;
  margin-left: 10px;
}

.hotlist_settings_item {
  margin-left: 26px;
  padding: 18px 0px 18px 0px;
  font-size: $medium-font;

  &.last {
    border-bottom: none;
  }
}

.hotlist_settings_item--container {
  width: 100%;
  border-bottom: $light-grey 1px solid;

  &.selected {
    background: #f5f5f5;
    color: #9e9e9e;
  }
}

.hotlist_settings_item--category {
  border-top: $light-grey 1px solid;
  border-bottom: $light-grey 1px solid;
  background-color: #e0e0e0;
  height: 26px;
  font-weight: bold;
  padding-left: 20px;
  padding-top: 4px;
  vertical-align: middle;
}

.hotlist_settings_item img {
  position: absolute;
}

.hotlist_settings_item--name {
  padding-left: 30px;
}

.hotlist_value {
  padding: 4px;
  display: flex;
  flex-direction: row;
}

.hotlist_values__row {
  margin-left: 4px;
  margin-right: 4px;

  &.one {
    padding: 2px 2px 0px 2px;
    font-size: $normal-font;
  }

  > span {
    font-size: $medium-font;
  }

  &.two > span {
    font-size: $small-font;
    padding: 0px 2px 2px 2px;
  }
}

.hotlist_value__rank {
  display: inline-block;
  padding: 2px;
  width: 20px;
  height: 20px;
  background-color: #ededed;
  margin-right: 9px;
  text-align: center;
  color: #333333;

  &[value-rank='1'] {
    background-color: #f9c716;
    mask-repeat: no-repeat;
    mask-image: url(../icons/Icon-crown.svg);
  }

  &[value-rank='2'] {
    background-color: #cecece;
    mask-repeat: no-repeat;
    mask-image: url(../icons/Icon-crown.svg);
  }

  &[value-rank='3'] {
    background-color: #cbb188;
    mask-repeat: no-repeat;
    mask-image: url(../icons/Icon-crown.svg);
  }
}

.hotlist_value__sort {
  display: inline-block;
  padding: 2px;
  width: 20px;
  height: 20px;
  background-color: #ededed;
  margin-right: -7px;
  margin-bottom: -5px;
  text-align: center;
  color: #333333;

  &[isdescending='true'] {
    background-color: #898989;
    mask-repeat: no-repeat;
    mask-image: url(../icons/Icon-Sort.svg);
  }

  &[isdescending='false'] {
    background-color: #898989;
    mask-repeat: no-repeat;
    mask-image: url(../icons/Icon-Sort-Up.svg);
  }
}

.hotlist_values__row {
  color: #333333;
}

.hotlist_value__symbol-name {
  color: $loss;

  &.notLink {
    color: #333333;
  }
}

.hotlist_value__value {
  > {
    .positive {
      color: $gain;
    }

    .negative {
      color: $loss;
    }

    .unsigned {
      color: #333333;
    }
  }

  float: right;
}

@media (max-width: $smallphone-max-breakpoint) {
  .hotlist_value {
    width: 100%;
  }
}

/* Phones */
/* For iPhone 5/SE We need the extra space for the stats container */
@media (max-width: $phone-max-breakpoint) {
  .hotlist_value {
    width: 100%;
  }

  /* .hotlist_value__value-primary,
    .hotlist_value__value-secondary, */

  .hotlist_value__value-tertiary,
  .hotlist_value__value-quaternary,
  .hotlist_value__value-quinary,
  .hotlist_value__value-senary {
    display: none;
  }
}

@media (max-width: $smallphone-landscape-max-breakpoint) {
  .hotlist_value {
    width: 100%;
  }

  /* .hotlist_value__value-primary,
    .hotlist_value__value-secondary, */

  .hotlist_value__value-tertiary,
  .hotlist_value__value-quaternary,
  .hotlist_value__value-quinary,
  .hotlist_value__value-senary {
    display: none;
  }
}

/* Portrait Tablets */
@media (min-width: 601px) and (max-width: $portrailt-tablet-max) {
  /* .hotlist_value__value-primary,
    .hotlist_value__value-secondary, */

  .hotlist_value__value-tertiary,
  .hotlist_value__value-quaternary,
  .hotlist_value__value-quinary,
  .hotlist_value__value-senary {
    display: none;
  }
}

/* Landscape and High-Res Tablets */
@media (min-width: $landscape-tablet-min) and (max-width: $landscape-tablet-max) {
  /* .hotlist_value__value-primary,
    .hotlist_value__value-secondary, */

  .hotlist_value__value-tertiary,
  .hotlist_value__value-quaternary,
  .hotlist_value__value-quinary,
  .hotlist_value__value-senary {
    display: none;
  }
}

/* Desktops */
@media (min-width: $desktop-min) {
  /* .hotlist_value__value-primary,
    .hotlist_value__value-secondary, */

  .hotlist_value__value-tertiary,
  .hotlist_value__value-quaternary,
  .hotlist_value__value-quinary,
  .hotlist_value__value-senary {
    display: none;
  }
}

.hotlist_no_data {
  border: 1px solid #d3d9e0;
  padding: 25px;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
}
/****** End Hotlist ********/

/****** Start Market Summary ******/

.market-summary-container {
  max-width: 1500px;
  margin: auto;
}

.market_summary_symbol--ranking {
  width: 50%;
  min-height: 236px;

  @media only screen and (min-width: $smallphone-max-breakpoint) and (max-width: 479px) {
    width: 100%;
  }

  @media only screen and (min-width: 480px) and (max-width: 767px) {
    width: 100%;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: 50%;
  }

  @media only screen and (min-width: 992px) {
    width: 50%;
  }

  float: left;
}

.flag-icon {
  background-image: url(../icons/sprite-mis.png);
  display: inline-block;
}

[class*='mi_flag_'] {
  margin-right: 3px;
  width: 16px;
  height: 11px;
}

.mi_flag_us {
  background-position: -703px 0px;
}

.mi_flag_cn {
  background-position: -448px 0px;
}

.mi_flag_hk {
  background-position: -533px 0px;
}

.mi_flag_kr {
  background-position: -833px 0px;
}

.mi_flag_tw {
  background-position: -686px 0px;
}

.mi_flag_vn {
  background-position: -720px 0px;
}

.mi_flag_id {
  background-position: -550px 0px;
}

.mi_flag_in {
  background-position: -567px 0px;
}

.mi_flag_au {
  background-position: -380px 0px;
}

.mi_flag_gb {
  background-position: -516px 0px;
}

.mi_flag_de {
  background-position: -465px 0px;
}

.mi_flag_fr {
  background-position: -499px 0px;
}

.mi_flag_ch {
  background-position: -431px 0px;
}

.mi_flag_ru {
  background-position: -635px 0px;
}

.mi_flag_tr {
  background-position: -669px 0px;
}

.mi_flag_br {
  background-position: -397px 0px;
}

.mi_flag_ar {
  background-position: -363px 0px;
}

.mi_flag_eu {
  background-position: -482px 0px;
}

.mi_flag_jp {
  background-position: -584px 0px;
}

.mi_flag_nz {
  background-position: -618px 0px;
}

.mi_flag_ca {
  background-position: -414px 0px;
}

.mi_flag_sg {
  background-position: -652px 0px;
}

.mi_flag_mx {
  background-position: -601px 0px;
}

.mi_flag_za {
  background-position: -737px 0px;
}

.mi_flag_oil {
  background-position: -782px 0px;
  width: 16px;
  height: 16px;
  margin-bottom: -2px;
}

.mi_flag_gold {
  background-position: -816px 0px;
  width: 16px;
  height: 16px;
  margin-bottom: -2px;
}

.mi_flag_wheat {
  background-position: -799px 0px;
  width: 16px;
  height: 16px;
  margin-bottom: -2px;
}

.market_summary_symbol {
  border-bottom: $light-grey 1px solid;
  padding: 4px;
  padding-left: 12px;
  padding-right: 10px;

  &.notLink {
    border-bottom: $light-grey 1px solid;
    background-color: #f5f5f5;
    padding: 4px;
    padding-left: 12px;
    padding-right: 10px;
  }

  .container {
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 180px 1fr 110px; 
    grid-template-rows: max-content; 
    gap: 0px 0px; 
    grid-template-areas: 
      "symbolName symbolFields symbolLast";
  }
  .symbolFields {
    overflow: hidden;
    height: 50px;
  }
}

.market_summary_symbol__container.last {
  padding-bottom: 25px;
}

.market_summary_symbol__row {
  margin-left: 4px;
  margin-right: 4px;

  &.one > span {
    font-size: $normal-font;
  }

  &.two {
    margin-top: 5px;
  }

  &.two > span {
    font-size: $small-font;
  }
}

$quoteFlashColor: #8d8d8d;
$changeUpColor: #f08080;
$changeDownColor: #8ec7ff;

.market_summary_symbol__quote-time {
  color: #9e9e9e;
}

.market_summary_symbol__quote-last {
  font-family: 'Roboto', sans-serif;
  color: #333333;
  font-weight: bold;
  font-size: $medium-font;
  margin-right: 4px;

  &.flash {
    color: $quoteFlashColor;
  }
}

.market_summary_symbol__quote {
  &.price-open,
  &.price-high,
  &.price-low,
  &.price-volume,
  &.price-prevclose,
  &.price-52high,
  &.price-52low,
  &.sparkline {
    font-family: 'Roboto', sans-serif;
    color: #333333;
    font-size: $medium-font;
    margin-right: 4px;
  }
  &.flash {
    color: $quoteFlashColor;
  }
}

.flash-up {
  box-shadow: 0 0 3px #ad5d5d;
  background-color: $changeUpColor;
}
.flash-down {
  box-shadow: 0 0 3px #6187ad;
  background-color: $changeDownColor;
}

.market_summary_symbol__net-change {
  float: right;
}

.market_summary_symbol__net-change-value {
  &.positive {
    color: $gain;
    &.flash {
      color: lighten($gain, 20%);
    }
  }

  &.negative {
    color: $loss;
    &.flash {
      color: lighten($loss, 20%);
    }
  }
}

.market_summary__symbol-name {
  color: #0055cc;
}

.market_summary_symbol--title_bar {
  margin-top: 15px;
  border-top: $light-grey 1px solid;
  border-bottom: $light-grey 1px solid;
  background-color: #e0e0e0;
  height: 26px;
  font-weight: bold;
  padding: 4px 10px 0px 12px;
  vertical-align: middle;

  .container {
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 180px 1fr 110px; 
    grid-template-rows: max-content; 
    gap: 0px 0px; 
    grid-template-areas: 
      "symbolName symbolFields symbolLast";
  }
  .symbolFields {
    overflow: hidden;
    height: 26px;
  }
 }


.market_summary_symbol-quote-card-header {
  &.symbol-name {
    width: 30%;
    min-width: 180px;
    display: inline-block;
  }

  &.price-open,
  &.price-high,
  &.price-low,
  &.price-volume,
  &.price-prevclose,
  &.price-52high,
  &.price-52low,
  &.sparkline {
    min-width: 100px;
    float: right;
    text-align: right;
    border: 0px solid black;
  }

  &.price-volume {
    min-width: 130px;
  }

  @media only screen and (max-width: 1222px) {
    &.price-52low {
      display: none;
    }
  }

  @media only screen and (max-width: 1092px) {
    &.price-52high {
      display: none;
    }
  }

  @media only screen and (max-width: 962px) {
    &.price-prevclose {
      display: none;
    }
  }

  @media only screen and (max-width: 832px) {
    &.price-volume {
      display: none;
    }
  }

  @media only screen and (max-width: 767.98px) {
    &.price-open,
    &.price-high,
    &.price-low,
    &.sparkline {
      display: none;
    }
  }

  &.price-last {
    min-width: 110px;
    float: right;
    text-align: right;
  }
}

.market_summary_symbol-quote-card {
  &.symbol-name {
    width: 30%;
    display: inline-block;
    white-space: nowrap;
  }

  &.price-open,
  &.price-high,
  &.price-low,
  &.price-volume,
  &.price-prevclose,
  &.price-52high,
  &.price-52low,
  &.sparkline {
    min-width: 100px;
    float: right;
    text-align: right;
    border: 0px solid black;
  }

  &.sparkline {
    padding-top: 5px;
  }

  &.price-volume {
    min-width: 130px;
  }

  @media only screen and (max-width: 1222px) {
    &.price-52low {
      display: none;
    }
  }

  @media only screen and (max-width: 1092px) {
    &.price-52high {
      display: none;
    }
  }

  @media only screen and (max-width: 962px) {
    &.price-prevclose {
      display: none;
    }
  }

  @media only screen and (max-width: 832px) {
    &.price-volume {
      display: none;
    }
  }

  @media only screen and (max-width: 767.98px) {
    &.price-open,
    &.price-high,
    &.price-low,
    &.sparkline {
      display: none;
    }
  }

  &.price-last {
    min-width: 114px;
    float: right;
    text-align: right;
  }
}

.margin.button.btn-cmn.global-news {
  margin-top: 14px;
  margin-bottom: 23.5px;
  font-size: $medium-font;
  padding: 6.5px 50px;
}

.market_summary_tse {
  padding-bottom: 40px;
}

.market_summary_tse--headline {
  margin: 20.5px 43.5px 15.5px 20.5px;
  font-weight: bold;
}

.market_summary_tse--timestamp {
  margin-right: 21px;
  text-align: end;
}

.market_summary_tse--content {
  margin: 16px 21px 0px 20px;

  &.collapse {
    height: 190px;
    overflow: hidden;
  }
}

.market_summary_tse--show {
  margin-top: 22px;
  color: $link;
  text-align: center;
  font-size: $medium-font;
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
}

.market_summary_tse--toggle {
  text-align: center;
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7px 0 7px;
  border-color: $light-grey transparent transparent transparent;
  display: inline-block;
}

.triangle-down {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7px 7px 7px;
  border-color: transparent transparent $light-grey transparent;
  display: inline-block;
}

.market_summary_industry_rankings--title_bar {
  padding-bottom: 15px;
}

.industry-ranking-row {
  padding: 6px 10px 6px 12px;
  border-bottom: $light-grey 1px solid;
  display: flex;
  flex-direction: row;
}

.industry-ranking-row-data {
  display: flex;
  flex: 1 1;
  flex-direction: row;
}

.industry-ranking_value__rank {
  display: inline-block;
  padding: 2px;
  width: 20px;
  height: 20px;
  background-color: #ededed;
  margin-right: 9px;
  text-align: center;
  color: #333333;
}

.industry-ranking_value__rank[value-rank='1'] {
  background-color: #f9c716;
  mask-repeat: no-repeat;
  mask-image: url(../icons/Icon-crown.svg);
}

.industry-ranking_value__rank[value-rank='2'] {
  background-color: #cecece;
  mask-repeat: no-repeat;
  mask-image: url(../icons/Icon-crown.svg);
}

.industry-ranking_value__rank[value-rank='3'] {
  background-color: #cbb188;
  mask-repeat: no-repeat;
  mask-image: url(../icons/Icon-crown.svg);
}

.industry-ranking_value__symbol-name {
  color: #0055cc;
  flex-grow: 1;
}

.industry-ranking_value__symbol-name.notLink {
  color: #333333;
}

.industry-ranking_value__value > .positive {
  color: $gain;
}

.industry-ranking_value__value > .negative {
  color: $loss;
}

.industry-ranking_value__value > .unsigned {
  color: #333333;
}

.industry-ranking_value__value {
  float: right;
  padding-right: 8px;
}

.industry-ranking__net-change {
  float: right;
}

.industry-ranking__net-change-value.positive {
  color: $gain;
}

.industry-ranking__net-change-value.negative {
  color: $loss;
}

.industry-ranking__arrow {
  display: flex;
  align-items: center;
}

.market_summary-link-row {
  width: 100%;
  display: inline-block;
  margin-bottom: 5px;
}

.market_summary-consensus-container {
  float: right;
  color: rgba(0, 0, 0, 0.5);
  font-size: 15.36px;
  position: relative;
  bottom: 0px;
  padding-right: 10px;
}

.market_summary-consensus-link {
  color: $link;
  margin-right: 5px;
}

.market_summary_symbol--title_price_ranking {
  margin-top: 15px;
  border-bottom: $light-grey 1px solid;
  height: 26px;
  font-weight: bold;
  padding-left: 12px;
  padding-top: 4px;
  vertical-align: middle;
  font-size: 13px;
}

.price-ranking_value__symbol-name {
  color: #0055cc;
  flex-grow: 1;
  font-size: $small-font;
}

.price-ranking__close-value {
  color: black;
  margin-right: 10px;
}

/******* Start Market Summary - market overview ************************/

.exchange-summary {
  padding-top: 21px;
  padding-left: 6px;
  padding-right: 6px;
}

.exchange-summary-header {
  line-height: 14px;
  padding-bottom: 2px;
  padding-top: 2px;
}

.exchange-summary-header-title {
  float: left;
  font-size: $medium-font;
  color: #0033cc;
  padding-bottom: 4px;
  
  &.notLink { color: black; }
  
}

.exchange-summary-header-timestamp {
  text-align: right;
  color: #616161;
  font-size: 11px;
  vertical-align: text-bottom;
}

.exchange-summary-ratios-color-bar {
  font-size: 2px;
  display: flex;
  margin: auto;
  clear: both;
  max-height: 4px;

  .exchange-summary-ratios-color-bar--up {
    background-color: $gain;
  }

  .exchange-summary-ratios-color-bar--down {
    background-color: $loss;
  }

  .exchange-summary-ratios-color-bar--unchanged {
    background-color: #bdbdbd;
  }
}

.exchange-summary-ratios-data {
  text-align: center;
  font-size: 15px;
  bottom: 0;
  padding: 5px 0px 5px 0px;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
}

.exchange-summary-ratio {
  color: #333333;
  margin-left: 10px;
  float: left;
}

.exchange-summary-ratio-icon {
  vertical-align: middle;
  height: 15px;
  margin-bottom: 2px;
}

.exchange-summary-ratios-up {
  color: $gain;
}

.exchange-summary-ratios-down {
  color: $loss;
}

.exchange-summary-ratios-unchanged {
  color: #333333;
}

.exchange-summary-ratios-total {
  color: #333333;
  font-size: $normal-font;
  text-align: right;

  > .total {
    text-align: left;
    margin-right: 10px;
    font-size: small;
  }
}

div.exchange-summary-detail-table {
  text-align: center;
  font-size: 15px;
  width: 100%;
}

.exchange-summary-detail-table {
  .exchange-summary-detail-table-cell {
    border-bottom: 1px solid $light-grey;  
    border-left: 1px solid $light-grey;   
    &.last-column {
      border-right:1px solid $light-grey;
    }     
  }
  .exchange-summary-detail-table-head {
    border-top: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;    
  }

  .exchange-summary-detail-table-heading {
    background: #ededed;
    font-size: $small-font;

    .exchange-summary-detail-table-head {
      font-weight: normal;
      color: #333333;
      text-align: center;
      border-left: 1px solid $light-grey;
      &.last-column {
        border-right:1px solid $light-grey;
      }
      &.session-detail{
        text-align: right;
        padding: 3px 0px 3px 0px;
      }    
      &.empty{
        border-left: none;
      }          
    }
  }

  display: table;
}

.exchange-summary-detail-table-row {
  display: table-row;
}

.exchange-summary-detail-table-heading {
  display: table-header-group;
}

.exchange-summary-detail-table-cell{
  display: table-cell;
}

.exchange-summary-detail-table-head {
  display: table-cell;
  padding: 3px;
}

.exchange-summary-detail-table-heading {
  display: table-header-group;
}

.exchange-summary-detail-table-body {
  display: table-row-group;
}

/******* End Market Summary - market overview ************************/

/****** End Market Summary ******/

/******* START DIALOG MODAL **/

.dialog-modal-content-hotlist {
  border-top: 5px solid #ffdd3f;
  padding: 0 !important;
}

.dialog-modal-content-nopadding {
  padding: 0 !important;
  &.small {
    width: 436px;
    height: 80vh;
    position: relative;
  }
}

.dialog-modal-navigation {
  border-top: 1px solid #bbb;
}

/******* END DIALOG MODAL ****/

.hotlist-settings-close-button {
  float: right;
}

.symbol-settings-close-button {
  position: absolute;
  float: left;
}

.article-navigation-wrap-button {
  
}

.article-header {
  height: 32px;
}

.article-timestamp {
  display: inline-block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.article-provider {
  float: right;
  background-color: #9e9e9e;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 7px;
  padding-right: 7px;
  font-size: 0.675rem;
  color: white;
}

.article-body {
  &.nowrap {
    padding-top: 15px;
    overflow-x: scroll;
    white-space: nowrap;
  }

  padding-top: 15px;
}

.article-body-pre {
  font-family: "MS Gothic","Hiragino Kaku Gothic","Courier",sans-serif;
  white-space: pre-wrap;
}

.headline-link {
  display: flex;
  flex-direction: row;
  max-height: inherit;
  position: relative;

  &.viewed {
    color: #800080;
  }
}

.related-symbol-plus-icon {
  line-height: 15px;
  position: absolute;
  right: -9px;
}

.headline-link-left {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.headline-link-left-title {
  flex-grow: 1;
  line-height: 1.8em;
  max-height: 4em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.headline-link-left-provider {
  align-self: flex-end;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 90%;
  font-size: $small-font;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 1.2rem;
  margin-left: -8px;
  padding: 4px 4px 4px 12px;
}

.headline-link-right {
  display: flex;
  align-items: center;
}

/******* END ARTCILE ****/

/******* START SCANS ****/

.scan-cards {
  font-size: $medium-font;
  margin-top: 4px;
}

.scan-search-criteria {
  font-size: $medium-font;
  margin-top: 4px;
}

.scan-search-criteria-container {
  border: 1px solid $light-grey;
  border-radius: 2px;
  margin: 15px 0px 0px 0px;
  position: relative;
  border-bottom: thin solid $light-grey;
  padding: 10px 16px 15px 16px;
  background-color: #FFFFFF;
}

.scan-search-criteria-title{
  font-size: $small-font;
  margin-bottom: 3px;
}

.scan-search-criteria-type {
  font-weight: bold;
  font-size: $small-font;
}

.scan-search-criteria-detail {
  font-size: $small-font;
  margin: 0px 10px 0px 16px;
}

.scan-search-criteria-save-button {
  text-align: center;
  margin-top: 16px;
}

.scan-search-criteria-edit-container {
  background-color: #FFFFFF;
  font-size: $small-font;
  padding: 0px 20px 0px 19px;
}

.button.btn-cmn.scan.save {
  background-color: #FFFFFF;
  border: 2px solid #1AAFFF;
  border-radius: 5px;
  font-size: $small-font;
  padding: 8px 10px 8px 12px;
  color: #1AAFFF;
  box-shadow: none;
}

.scan-check-icon {
  vertical-align: middle;
  height: 12px;
  margin-bottom: 2px;
  margin-right: 4px;
}

.scan-search-result-container {
  background: #F5F5F5;
  padding: 13px 0px 12px 7px;
  color: #000;
}

.scan-search-result-count-number {
  margin: 0px 3px 0px 52px;
  color: $green;
  font-weight: bold;
  font-size: $normal-font;
}

.scan-search-result-count {
  font-size: $medium-font;
}

.scan-search-result-count {
  font-size: $medium-font;
}

.scan-search-result-display-amount {
  font-size: $small-font;
  &.bottom {
    text-align: center;
    font-size: $medium-font;
  }
}

.scan-result-symbol-container {
  border-bottom: 1px solid $light-grey;
}

.scan-result-symbol-row-one {
    margin: 12px 15px 0px 10px;
}

.scan-result-symbol-name {
  color: #0055CC;
  font-size: 16;
}

.scan-result-symbol-last {
  color: #333333;
  font-size: 16;
  float: right;
}

.scan-result-symbol-row-two {
  margin: 0px 15px 7px 10px;
  vertical-align: top;
}

.scan-result-symbol-detail {
  position: relative;
  bottom:5px;
  color: #333333;
  font-size: $small-font;
}

.scan-result-symbol-net-change {
  &.positive {
    color: $gain;
  }

  &.negative {
    color: $loss;
  }
  color: #333333;
  font-size: $normal-font;
  float: right;
}

// Do not show in phase one (back to search button)
// .scan-search-result-back-search{
//   font-size: 10px;
//   margin: 0px 0px 31px 17px;
//   &.right {
//     color: #193A9E;
//     margin-left: 7px;
//   }
//   &.left {
//     margin: 0px;
//   }
// }

.scan-card {
  background: #fafafa;
  border-radius: 2px;
  margin: 15px 15px;
  position: relative;
  border-bottom: thin solid $light-grey;

  .symbol-name {
    word-wrap: break-word;
    display: inline-block;
    width: calc(100% - 66px); // 50px for the delete button, 16px for the left-margin of the name
  }
  
  .delete-scan-button {
    vertical-align: top;
  }
}

.scan-section-header {
  font-size: 18px;
  padding: 22px 0px 22px 7px;
  font-weight: bold;
} 

.margin.button.btn-cmn.scan.result {
  background-color: $grey;
  font-size: $small-font;
  margin: 0px 0px 0px 16px;
  padding: 8px 10px 8px 12px;
  color: #525E93;

  &.add-criteria {
    padding-top: 5px;
    margin-left: 0px;
    font-size: $normal-font;
    height: 30px;
    width: calc(100% - 22px);
    text-align: center;
    color: #FFFFFF;
    background-color: #BDBDBD;

    .add-criteria-label {
      padding-top: 5px;
      font-weight: normal;
    }

    .add-criteria-icon {
      padding-top: 1px;
    }
  }

  &.save-criteria {
    padding-top: 5px;
    margin-left: 0px;
    font-size: $small-font;
    height: 30px;
    width: calc(100% - 24px);
    text-align: center;
    background-color: #FFFFFF;
    border-color: #9AC2DF;
    border-width: thin;
    border-style: solid;

    .save-criteria-svg-label {
      color: #518FAD;
      display: inline-block;
      vertical-align: top;
    }
    
    .save-criteria-label {
      padding-top: 8px;
      font-weight: normal;
      display: inline-block;
    }

    .save-criteria-icon {
      display: inline-block;
      margin-right: 4px;

      svg {
        color: #518FAD;
        margin-top: -3px;
      }
    }

    &.disabled {
      color: rgba(0, 0, 0, 0.26);
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.12);      
    }
  }

  &.edit-criteria {
    padding-top: 5px;
    margin-left: 0px;
    font-size: $small-font;
    height: 30px;
    width: calc(100% - 54px);
    text-align: center;
    background-color: #FFFFFF;
    border-color: #9AC2DF;
    border-width: thin;
    border-style: solid;

    .edit-criteria-svg-label {
      color: #518FAD;
      display: inline-block;
      vertical-align: middle;
    }

    .edit-criteria-label {
      padding-top: 5px;
      font-weight: normal;
    }

    &.disabled {
      color: rgba(0, 0, 0, 0.26);
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
}

.my-scan-icon {
  vertical-align: middle;
  height: 15px;
  margin-bottom: 2px;
  margin-right: 4px;
}

.scan-card-header {
  text-size-adjust: 100%;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background: $grey;
  font-size: $medium-font;
  line-height: 1.2rem;
  line-height: 21px;
  border-bottom: thin solid $light-grey;
  color: #000;
  font-weight: bold;

  .symbol-name {
    margin: 14px 0px 14px 16px;
  }

  &.static {
    background: #D3E2F0;
  }
}

.scan-card-column {
  padding: 10px 16px 15px 16px;
  background-color: #f5f5f5;

  &.static {
    background: #F2F5F7;
  }
}

.scan-card-column-register-date {
  font-size: 10px;
  margin-bottom: 15px;
}

.scan-card-column-item-title {
  font-weight: bold;
}

.scan-card-column-item-detail {
  margin: 0px 10px 10px 14px;
}

.scan-card-column-item-count {
  margin-right: 16px;
  float: right;
}

.margin.button.btn-cmn.scans {
  font-size: $small-font;
  margin-top: 5px;
  padding: 11px 0px 5px 0px;
  width: calc(100% - 10px);
  height: 25px;

  svg {
    color: inherit;
  }

  .icon {
    margin-top: -2px;
    display: inline-block;
    margin-right: 4px;
  }

  .label {
    vertical-align: top;
    display: inline-block;
  }
}

.pagination {
  display: inline-block;
  padding-left: 0px;
  padding-right: 0px;
  font-family: "ヒラギノ角ゴシック ProN","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,"ＭＳ Ｐゴシック","MS PGothic",sans-serif;
}

.pagination-container{
 text-align: center;
 margin-bottom: 22px;
}

.pagination li {
  display: inline-block;
  margin: 0px 3px;
  background-color: #F0E6C9;


  &.break-me{
    background-color: white;
  }
  &.next,
  &.previous {
    background-color: #d7d7d7;
  }
  &.next.disabled,
  &.previous.disabled {
    background-color: #eeeeee;
  }
  &.active {
    background-color:#E1C561;
    color: #FFFFFF;
    border-bottom: 2px solid #927A22;
  }  
}

.pagination-button{
  width:38px;
  height:40px;
  max-width:38px;    
  line-height: 40px;
  &:hover {
    background: #E9D695;
  }
  &.arrow {
    color: #fff;
    width:27px;
    max-width:27px;
    &:hover {
      background: #bfbfbf;
    }
  }
}
.pagination-button-break{
  width:9px;
  height:40px;
  max-width:24px;    
  line-height: 40px;
}

.scan-subcategory-container {
  margin-top: 10px;
}

.scan-subcategory-selector {
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: $grey;
  border-radius: 5px;

  .title-bar {
    .title {
      display: inline-block;
      padding-left: 10px;
      padding-top: 7px;
      font-weight: bold;
      font-size: $normal-font;
    }
    
    .delete-button {
      display: inline-block;
      padding-right: 4px;
      padding-top: 4px;
      float: right;
    }
  }

  .button-selection-group {
    padding-bottom: 5px;

    .number-input-wrapper {
      display: inline-block;
      width: 35%;
      max-width: 250px;
      padding-left: 10px;
      padding-right: 10px;

      &.wide {
        width: 70%;
        max-width: 500px;
      }

      // https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }

      .number-input {
        width: 100%;
      }
    }
  }
}

/******* END SCANS ****/

/******* START NEWSCANS ****/

.scan-container {
  max-width: 1500px;
  margin: auto;
}

.scan-section {
  max-width: 1500px;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;

  .results {
    background-color: $grey;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
    padding: 30px 10px 20px 10px;
    position: relative;
    display: block;
    border-radius: 5px;

    .results_count {
      margin-left: 20px;
      margin-right: 3px;
      color: $green;
      font-weight: bold;
    }

    .scan-view {
      background-color: $green;
      color: white;
      font-size: $medium-font;
    }
  }

  .results:after{
    position: absolute;
    top: 0;
    height: 0;
    width: 0;
    left: calc(50% - 15px);
    border: 15px solid transparent;
    border-top-color: white;
    content: "";
  }
   .scan-view {
     margin: 10px;
     width:100%;
     left: -10px;
   }
}

.scan-title {
  font-size: $title-font;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 15px;
  display: inline-block;
}

.scan-subheading {
  font-size: 14px;
  margin-bottom: 15px;
}

.my-scans-button {
  float: right;
  margin-top: 15px;
}

.scan-my-search-list-button {
  padding: 6px 9px 6px 9px !important;
  background-color: $grey !important;
  margin-right:10px;
  border: 1px solid #C6C6C6 !important;
}

.scan-apply-button {
  padding: 5px 9px 5px 10px !important;
  font-size: 13px;
  float: right;
}

.scan-name {
  height: 35px;
  position: relative;
  margin-bottom: -7px;

  .scan-name-label {
    width: calc(100% - 10px);
    display: inline-block;
    position: absolute;
    bottom: 0px;
    margin-bottom: 5px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .scan-name-field {
    margin-left: 5px;
    width: calc(100% - 10px);
  }
}

/******* END NEWSCANS ****/

$historical-border: 1px solid $light-grey;
$lowest-color: #146DC833;
$highest-color: #E0151533;

.symbol-details-historical-container {
  max-width: 1500px;
  margin: auto;
}

.symbol-historial-selectors {
  margin-left: 10px;
  &.realtime {
    position: absolute;
    z-index: 100;
  }
}

.symbol-historial-period-span {
  margin-left: 20px;
}

.symbol-historial-period-select {
  min-width: 100px;
}

.symbol-historical-legend-container {
  display: inline-block;
  margin-top: 5px;
  width: 100%;

  .legend {
    padding: 4px;
    border: $historical-border;
    margin-top: 6px;
    margin-bottom: 2px;
    margin-right: 10px;
    float: right;
  
    .label {
      display: inline-block;
      font-size: $small-font;
      margin-left: 4px;
    }
  
    .lowest {
      display: inline-block;
      width: 8px;
      height: 8px;
      background-color: $lowest-color;
      vertical-align: middle;
      margin-left: 10px;
    }
  
    .highest {
      display: inline-block;
      width: 8px;
      height: 8px;
      background-color: $highest-color;
      vertical-align: middle;
    }
  }
}

.symbol-historical-data {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;

  .header {
    border-top: $historical-border;
    background-color: #EDEDED;
    font-weight: bold;
    font-size: $medium-font;

    .cell {
      .subcell {
        
      }

      .multirow-cell {

      }
    }
  }

  .row {
    width: 100%;
    display: flex;
    border-bottom: $historical-border;
    
    &:nth-child(odd) {
      background-color: #F5F5F5;
    }

    .cell {
      padding-left: 3px;
      padding-right: 3px;
      box-sizing: border-box;
      display: block;
      text-align: center;

      // Date
      &:nth-child(1) {
        width: 20%;
      }
      // High/Low
      &:nth-child(2) {
        width: 24%;
      }
      // Open/Close
      &:nth-child(3) {
        width: 24%;
      }
      // Change/Volume
      &:nth-child(4) {
        width: 32%;
      }

      .subcell {
        width: 100%;
        text-align: right;

        &.lowest {
          background-color: $lowest-color;
          // text-shadow: 0px 0px 3px $loss;
        }
        &.highest {
          background-color: $highest-color;
          // text-shadow: 0px 0px 3px $gain;
        }

        .colorful {
          &.negative {
            color: $loss;
          }
    
          &.positive {
            color: $gain;
          }
        }
      }
    }

    .multirow-cell {
      font-size: $medium-font;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: $historical-border;
    }
  }
}

.nopage {
  ul {
    text-align: center;
  }
  ul > li > a {
    margin-bottom: 25px;
    line-height: 3em;
    width: 80%;
    max-width: 500px;
    border: 2px solid #e1c561;
    color: #ad9951;
  }
}

/*** START: Ticker ***/
$changeArrowPadding: 14px;

$gainColor: #ff0000;
$lossColor: #0363d8;
$unchColor: #5a5a5a;

$gray_10: #f5f5f5;

$xbTextColor: #111;

$tickerBackgroundColor: #eceff1;
$tickerBorderTop: 1px solid #b6bcc2;
$tickerHeight: 45px;
$tickerWidth: 980px;
$tickerZIndex: 81000;

$tickerItemColor: #333333;
$tickerItemFontSize: 13px;
$tickerItemSpacing: 12px;

$tickerDescHoverColor: lighten( $tickerItemColor, 5% );

$tickerValuePulseColor: lighten( $tickerItemColor, 20% );
$tickerValueSpacing: 4px;

$tickerValueGainColor: #e01515;
$tickerValueGainPulseColor: #ff6666;
$tickerValueLossColor: #146dc8;
$tickerValueLossPulseColor: #80aaff;
$tickerValueUnchColor: $unchColor;
$tickerValueUnchPulseColor: lighten( $unchColor, 20% );

.s-fz-12 {
  font-size: 14px;
  line-height: 18px;
}

.indexes{
  .list{
    overflow: hidden;
    width:100%;

    li, div {
      display: inline-block;
      background: $gray_10;
      min-width: 195px;
      width: calc(20% - 1px);
      margin-left: 1px;
      padding: 5px 5px 3px;
      box-sizing: border-box;
      &:first-child{
        margin: 0;
      }
    }
    .ttl{
      line-height: 1;
    }
    .num{
      font-family: unset;
      font-weight: bold;
      margin: 0 5px 0 0;
    }
  }
}

@mixin gain
{
    color: $gainColor;  

    &:after,
    &:before
    {
        color: $gainColor;
    }

    &.first {
        margin-right: 6px;
    }

    
    &.signed:before
    {
        content: '+';
        letter-spacing:-0.05em;
        font-size: 11px;
    }

    &.arrow:before {
      font-size: 9px;
      content: "\25B2";
    }

    &.color-disabled {
        color: $xbTextColor !important;

        &:after,
        &:before {
            color: $xbTextColor !important;
        }
    }
}

@mixin loss {
  color: $lossColor;

  &:after,
  &:before {
      color: $lossColor;
  }

  &.signed:before {
      content: '-';
  }

  &.first {
      margin-right: 6px;
  }

  &.arrow:before {
    font-size: 9px;
    content: "\25BC";
  }

  &.color-disabled {
      color: $xbTextColor !important;

      &:after,
      &:before {
          color: $xbTextColor !important;
      }
  }
}

@mixin unch
{
  color: $unchColor;

  &:after,
  &:before
  {
      color: $unchColor;
  }

  &.arrow:before {
      content: '▶';
  }

  &.first {
      margin-right: 6px;
  }
}

#index-ticker
{
  // max-width: 1500px;
  margin: auto;
}

@mixin box-shadow($shadow: 0 1px 3px rgba(0,0,0,.25)) {
  -webkit-box-shadow: $shadow;
     -moz-box-shadow: $shadow;
          box-shadow: $shadow;
}

@mixin tickerBackground
{
    background: $tickerBackgroundColor;
}

.index-ticker-container
{
  width: 100%;
  height: 80px;
    > .indexes
    {
        background: #fff;
        position: relative;
        z-index: 10;
    }

    &.fixed-top > .indexes
    {
        background: #fff;
        box-shadow: 0px 3px 4px #9e9e9e;
        position: fixed;
        top: 0;
        z-index: $tickerZIndex;
    }

    & .gain {
      @include gain;
    }

    & .loss {
      @include loss;
    }

    & .unch {
      @include unch;
    }
}

.indexes-ticker-list
{
    min-width: $tickerWidth;
}

.ticker
{
    @include box-shadow( 0 2px 4px rgba(0,0,0,.35) );
    @include tickerBackground;
    border-top: $tickerBorderTop;
    height: $tickerHeight;
    overflow: hidden; // Clip long inner ul and prevent horizontal scrollbar

    html.ie8 &
    {
        box-shadow: #888 0 0 4px;
    }
}

.ticker-desc
{
    color: $tickerItemColor;
    font-size: 12px;
    font-weight: bold;

    &:active,
    &:hover,
    &:visited {
      color: $tickerItemColor;
    }
}

.ticker-value
{
    font-family: unset;
    font-weight: bold;
    font-size: 12px;

    &.pulse {
        color: $tickerValuePulseColor;
    }
}

.ticker-value.gain
{
    color: $tickerValueGainColor;
    margin-right: 0px;

    &.first {
        margin-right: $tickerValueSpacing;
    }

    &.flash
    {
        color: $tickerValueGainPulseColor;
    }

    &:after, &:before
    {
        font-weight: bold;
        color: $tickerValueGainColor;
    }
    
    &.arrow::before {
      content: "\25B2";
    }
}

.ticker-value.loss
{
    color: $tickerValueLossColor;
    margin-right: 0px;

    &.first {
        margin-right: $tickerValueSpacing;
    }

    &.flash
    {
        color: $tickerValueLossPulseColor;
    }

    &:after, &:before
    {
        font-weight: bold;
        color: $tickerValueLossColor;
    }
        
    &.arrow::before {
      content: "\25BC";
    }
}

.ticker-value.unch
{
    color: $tickerValueUnchColor;
    margin-right: 0px;

    &.first {
        margin-right: $tickerValueSpacing;
    }

    &.flash
    {
        color: $tickerValueUnchPulseColor;
    }

    &:after, &:before
    {
        font-weight: bold;
        color: $tickerValueUnchColor;
    }

    &:before
    {
        content: '\a0';
        display: inline;
    }
}

/*** END: Ticker ***/

